<template>
  <div>
    <div>
      <!--            <v-img :src="`/static/image/promotion/banner/${currentLanguage}/promotion_banner.jpg`" class="hidden-sm-and-down" width="1920" height="380"></v-img>-->
      <!--            <v-img :src="`/static/image/promotion/banner/${currentLanguage}/mpromotion-banner.jpg`" class="hidden-md-and-up"></v-img>-->
      <div class="vendor-list-container" v-if="!hideVendorSelection">
        <div class="vendor-list text-center">
          <div class="vendor-list-item">
            <v-btn
                height="auto"
                min-height="auto"
                :class="type == 'luckyspin' ? 'active white--text' : ''"
                @click="navigateToLuckySpin"
                class="text-center title font-weight-bold my-0 pa-4 vendor-list-item-button"
            >
              {{ $t(`label.wheelSpin`) }}
            </v-btn>
          </div>
          <div class="vendor-list-item">
            <v-btn
                height="auto"
                min-height="auto"
                :class="type == 'agerewards' ? 'px-10 py-5 active white--text' : 'px-10 py-5'"
                @click="navigateToRewardsStore"
                class="text-center title font-weight-bold my-0 pa-4 vendor-list-item-button rewards"
            >
              <v-badge
                  color="red accent-4"
                  content="New"
              >
                {{ $t(`label.rewardsStore`) }}
              </v-badge>
            </v-btn>
          </div>
          <div class="vendor-list-item"
               v-if="false">
            <v-btn
                height="auto"
                min-height="auto"
                :class="type == 'dailycheckin' ? 'active white--text' : ''"
                @click="navigateToDailyCheckIn"
                class="text-center title font-weight-bold my-0 pa-4 vendor-list-item-button"
            >
              {{ $t(`label.dailyCheckIn`) }}
            </v-btn>
          </div>
<!--          <div class="vendor-list-item">-->
<!--            <v-btn-->
<!--                height="auto"-->
<!--                min-height="auto"-->
<!--                :class="type == 'angpao' ? 'active white&#45;&#45;text' : ''"-->
<!--                @click="navigateToAngpao"-->
<!--                class="text-center title font-weight-bold my-0 pa-4 vendor-list-item-button"-->
<!--            >-->
<!--              <v-badge-->
<!--                  color="red accent-3"-->
<!--                  content="Hot"-->
<!--              >-->
<!--                {{ $t(`label.angpao`) }}-->
<!--              </v-badge>-->
<!--            </v-btn>-->
<!--          </div>-->
          -
        </div>
      </div>
    </div>
    <router-view :rewardType="type"></router-view>
  </div>
</template>

<script>
import {ROUTE_NAME} from '@/constants/route.constants'
import {CACHE_KEY} from '@/constants/constants'
import {uiHelper, locale} from '@/util'
import {REWARDS_URL} from "@/store/member.module";

export default {
  name: "rewardLayout",
  metaInfo: {
    title: `${locale.getMessage('meta.promotion_title')}`,
    titleTemplate: `%s`,
    meta: [{charset: 'utf-8'}, {
      name: 'description',
      content: `${locale.getMessage('meta.promotion_description')}`
    }, {name: 'keyword', content: `${locale.getMessage('meta.promotion_keyword')}`}]
  },

  data: () => ({
    currentLanguage: uiHelper.getLanguage(),
    hideVendorSelection: false,
    routeName: ROUTE_NAME,
    searchCriteria: {
      gameType: '',
      pageNumber: 1,
      pageSize: 20
    },
    storageGameProviderType: uiHelper.getLocalStorage(`${CACHE_KEY.GAME_PROVIDER_TYPE}_${uiHelper.getPlatform()}_${uiHelper.getCurrency()}`)
  }),
  computed: {
    type() {
      return this.$route.params.type
    },
    isLoggedIn() {
      return this.$store.state.member.isLoggedIn
    },

  },
  watch: {
    type() {
      return this.$route.params.type
    },

  },
  created() {
  },
  methods: {
    navigateToRewardsStore() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'agerewards',
          ageUrl: this.rewardsUrl
        }
      })
    },
    navigateToLuckySpin() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'luckyspin'
        }
      })
    },
    navigateToAngpao() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'angpao'
        }
      })
    },
    navigateToDailyCheckIn() {
      this.$router.push({
        name: ROUTE_NAME.REWARDS_BY_TYPE,
        params: {
          type: 'dailycheckin'
        }
      })
    },
    // updatePromotionFilterType(type) {
    //     this.searchCriteria.gameType = type
    // },
    // initHideVendorSelection() {
    //     this.hideVendorSelection = this.$route.name == 'promotion-history' || this.$route.name == 'promotion-in-progress'
    // },
    openLoginDialog() {
      this.$parent.openLoginDialog()
    }
  }
}
</script>

<style lang="scss">
.promotion-link-button {
  margin-right: 8px;
}

.promotion-link-button.v-btn--active::before {
  opacity: 0 !important;
}

.promotion-category-seletion {
  padding: 12px 24px;
}

.promotion-card-select {
  width: 100%;
  max-width: 1400px;
  margin: auto;

  .promotion-card-item {
    width: 50%;
    padding: 0px 10px !important;
    display: inline-block;
  }

  .v-card {
    position: relative;
    border-top-right-radius: 20px !important;
    border-top-left-radius: 20px !important;
    border-bottom-right-radius: 0px !important;
    border-bottom-left-radius: 0px !important;

    .promotion-image-box {
      max-height: 250px;
      max-width: 680px;
      border-top-right-radius: 20px !important;
      border-top-left-radius: 20px !important;

      .v-image__image {
        background-position: center -20px !important;
      }
    }
  }

  .promotion-name-box {
    font-size: 16px;
    border-bottom-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
}

.promotion-iframe {
  width: 100%;
  height: 500px;
}

.vendor-list-container {
  padding-bottom: 20px;
}

.vendor-list-item {
  display: inline-block;
  padding: 0 10px;

  .vendor-list-item-button {
    background-image: linear-gradient(to bottom, #273270, #1C244F);
    letter-spacing: 0;
    min-width: 150px !important;

    &.rewards {
      min-width: 150px !important;
    }

    &.active {
      background-image: linear-gradient(to left, var(--v-primary-base), var(--v-primary_3-base));
    }
  }
}

@media (max-width: 1650px) {
}

@media (max-width: 1500px) {
  .vendor-list-item .vendor-list-item-button {
    min-width: 100px !important;

    .v-btn__content {
      font-size: 18px;
    }
  }
}

@media (max-width: 1350px) {
}

@media (max-width: 1263px) {
  .promotion-card-select .v-card .promotion-image-box {
    height: 170px;
  }
}

@media (max-width: 1024px) {
  .vendor-list-item .vendor-list-item-button .v-btn__content {
    font-size: 16px;
  }

  .vendor-list-item-button {
    padding: 10px !important;
  }
}

@media (max-width: 959px) {
  .vendor-list-container {
    padding: 10px 0px;
  }

  .vendor-list-item {
    .vendor-list-item-button {
      box-shadow: none !important;
      border-radius: 0px !important;
    }
  }

  .vendor-list-item .vendor-list-item-button {
    height: 40px;
    width: 100px;
  }
  .promotion-category-seletion {
    padding: 4px 0px;

    div {
      margin: auto;
    }
  }

  .promotion-card-select {
    text-align: center;

    .promotion-card-item {
      max-width: 700px;
      width: 100%;
      margin: auto;
    }
  }
}

@media (max-width: 599px) {
  .promotion-card-select .promotion-card-item {
    width: 380px;
  }
  .promotion-card-select .v-card .promotion-image-box {
    height: 115px;
  }

  .vendor-list-item {
    margin-bottom: 10px;
  }
}

@media (max-width: 425px) {
  .promotion-category-seletion {
    padding: 12px 0px;
  }

  .vendor-list-item {
    padding: 0 5px;
  }

  .vendor-list-item .vendor-list-item-button .v-btn__content {
    font-size: 12px;
  }

  .promotion-card-select .promotion-card-item {
    width: 300px;
  }

  .promotion-card-select .v-card .promotion-image-box {
    height: 100px;
  }

  .promotion-link-button {
    margin-right: 0px !important;

    .v-icon {
      font-size: 26px !important;
    }
  }
}
</style>
